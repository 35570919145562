define("discourse/plugins/discourse-ratings/discourse/components/rating-actions", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-ratings-action">
    <h3>{{i18n "admin.ratings.migrate.title"}}</h3>
  
    <div class="description">
      {{i18n "admin.ratings.migrate.description"}}
    </div>
  
    <div class="controls">
      {{rating-migrate ratingTypes=this.ratingTypes}}
    </div>
  </div>
  
  <div class="admin-ratings-action">
    <h3>{{i18n "admin.ratings.destroy.title"}}</h3>
  
    <div class="description">
      {{i18n "admin.ratings.destroy.description"}}
    </div>
  
    <div class="controls">
      {{rating-destroy ratingTypes=this.ratingTypes}}
    </div>
  </div>
  */
  {
    "id": "Ppbtlmqt",
    "block": "[[[10,0],[14,0,\"admin-ratings-action\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"admin.ratings.migrate.title\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"description\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"admin.ratings.migrate.description\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"ratingTypes\"],[[30,0,[\"ratingTypes\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-ratings-action\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"admin.ratings.destroy.title\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"description\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"admin.ratings.destroy.description\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"ratingTypes\"],[[30,0,[\"ratingTypes\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"h3\",\"i18n\",\"rating-migrate\",\"rating-destroy\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/components/rating-actions.hbs",
    "isStrictMode": false
  });
  const RatingActions = dt7948.c(class RatingActions extends _component.default {}, [(0, _component2.classNames)("ratings-action-controls")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RatingActions);
});
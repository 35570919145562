define("discourse/plugins/discourse-ratings/discourse/connectors/topic-list-after-title/topic-list-rating", ["exports", "discourse/plugins/discourse-ratings/discourse/helpers/rating-list", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _ratingList, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @outletArgs.topic.show_ratings}}
      {{ratingList @outletArgs.topic.ratings topic=@outletArgs.topic linkTo=true}}
    {{/if}}
  
  */
  {
    "id": "sWqbm4lx",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"topic\",\"show_ratings\"]],[[[1,\"    \"],[1,[28,[32,0],[[30,1,[\"topic\",\"ratings\"]]],[[\"topic\",\"linkTo\"],[[30,1,[\"topic\"]],true]]]],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ratings/discourse/connectors/topic-list-after-title/topic-list-rating.js",
    "scope": () => [_ratingList.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "topic-list-rating"));
});
import { template as template_f8e9c6645ad74d97b83f10c54d59774b } from "@ember/template-compiler";
const FKLabel = template_f8e9c6645ad74d97b83f10c54d59774b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

define("discourse/plugins/discourse-ratings/discourse/components/rating-migrate", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "discourse-common/utils/decorators", "discourse/plugins/discourse-ratings/discourse/models/rating", "@ember/template-factory"], function (_exports, _component, _object, _component2, _decorators, _rating, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{category-chooser
    value=this.categoryId
    onChange=(action (mut this.categoryId))
  }}
  
  {{combo-box
    value=this.fromType
    content=this.ratingTypes
    valueProperty="type"
    onChange=(action (mut this.fromType))
    options=(hash none="admin.ratings.type.select")
  }}
  
  {{combo-box
    value=this.toType
    content=this.ratingTypes
    valueProperty="type"
    onChange=(action (mut this.toType))
    options=(hash none="admin.ratings.type.select")
  }}
  
  {{d-button
    action=(action this.migrate)
    label="admin.ratings.migrate.btn"
    disabled=this.migrateDisabled
  }}
  
  {{#if this.startingMigration}}
    {{loading-spinner size="small"}}
  {{/if}}
  
  {{#if this.migrationMessage}}
    <div class="action-message">
      {{i18n this.migrationMessage}}
    </div>
  {{/if}}
  */
  {
    "id": "DoSTUAhk",
    "block": "[[[1,[28,[35,0],null,[[\"value\",\"onChange\"],[[30,0,[\"categoryId\"]],[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"categoryId\"]]],null]],null]]]]],[1,\"\\n\\n\"],[1,[28,[35,3],null,[[\"value\",\"content\",\"valueProperty\",\"onChange\",\"options\"],[[30,0,[\"fromType\"]],[30,0,[\"ratingTypes\"]],\"type\",[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"fromType\"]]],null]],null],[28,[37,4],null,[[\"none\"],[\"admin.ratings.type.select\"]]]]]]],[1,\"\\n\\n\"],[1,[28,[35,3],null,[[\"value\",\"content\",\"valueProperty\",\"onChange\",\"options\"],[[30,0,[\"toType\"]],[30,0,[\"ratingTypes\"]],\"type\",[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"toType\"]]],null]],null],[28,[37,4],null,[[\"none\"],[\"admin.ratings.type.select\"]]]]]]],[1,\"\\n\\n\"],[1,[28,[35,5],null,[[\"action\",\"label\",\"disabled\"],[[28,[37,1],[[30,0],[30,0,[\"migrate\"]]],null],\"admin.ratings.migrate.btn\",[30,0,[\"migrateDisabled\"]]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"startingMigration\"]],[[[1,\"  \"],[1,[28,[35,7],null,[[\"size\"],[\"small\"]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"migrationMessage\"]],[[[1,\"  \"],[10,0],[14,0,\"action-message\"],[12],[1,\"\\n    \"],[1,[28,[35,9],[[30,0,[\"migrationMessage\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"category-chooser\",\"action\",\"mut\",\"combo-box\",\"hash\",\"d-button\",\"if\",\"loading-spinner\",\"div\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/components/rating-migrate.hbs",
    "isStrictMode": false
  });
  const noneType = "none";
  const RatingMigrate = dt7948.c(class RatingMigrate extends _component.default {
    migrateDisabled(categoryId, toType, fromType) {
      return [categoryId, toType, fromType].any(i => !i) || toType !== noneType && fromType !== noneType;
    }
    static #_ = (() => dt7948.n(this.prototype, "migrateDisabled", [(0, _decorators.default)("categoryId", "toType", "fromType")]))();
    migrate() {
      let data = {
        category_id: this.categoryId,
        type: this.fromType,
        new_type: this.toType
      };
      this.set("startingMigration", true);
      _rating.default.migrate(data).then(result => {
        if (result.success) {
          this.set("migrationMessage", "admin.ratings.migrate.started");
        } else {
          this.set("migrationMessage", "admin.ratings.error.migration_failed_to_start");
        }
      }).finally(() => this.set("startingMigration", false));
    }
    static #_2 = (() => dt7948.n(this.prototype, "migrate", [_object.action]))();
  }, [(0, _component2.classNames)("rating-action-controls")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RatingMigrate);
});
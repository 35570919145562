import { template as template_40610eb5114a4f28afbc1174143cfcbd } from "@ember/template-compiler";
const SidebarSectionMessage = template_40610eb5114a4f28afbc1174143cfcbd(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

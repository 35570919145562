define("discourse/plugins/discourse-ratings/discourse/components/topic-rating-tip", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember-decorators/component", "jquery", "@ember/template-factory"], function (_exports, _component, _object, _runloop, _component2, _jquery, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a role="button" {{on "click" (action "toggleDetails")}}>
    {{d-icon "circle-info"}}
  </a>
  {{#if this.showDetails}}
    <div class="tip-details">
      {{html-safe (i18n this.details)}}
    </div>
  {{/if}}
  */
  {
    "id": "O0hpTOe0",
    "block": "[[[11,3],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0],\"toggleDetails\"],null]],null],[12],[1,\"\\n  \"],[1,[28,[35,3],[\"circle-info\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"showDetails\"]],[[[1,\"  \"],[10,0],[14,0,\"tip-details\"],[12],[1,\"\\n    \"],[1,[28,[35,6],[[28,[37,7],[[30,0,[\"details\"]]],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"a\",\"on\",\"action\",\"d-icon\",\"if\",\"div\",\"html-safe\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/components/topic-rating-tip.hbs",
    "isStrictMode": false
  });
  const TopicRatingTip = dt7948.c(class TopicRatingTip extends _component.default {
    didInsertElement() {
      super.didInsertElement(...arguments);
      (0, _jquery.default)(document).on("click", (0, _runloop.bind)(this, this.documentClick));
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      (0, _jquery.default)(document).off("click", (0, _runloop.bind)(this, this.documentClick));
    }
    documentClick(e) {
      let $element = (0, _jquery.default)(this.element);
      let $target = (0, _jquery.default)(e.target);
      if ($target.closest($element).length < 1 && this._state !== "destroying") {
        this.set("showDetails", false);
      }
    }
    toggleDetails() {
      this.toggleProperty("showDetails");
    }
    static #_ = (() => dt7948.n(this.prototype, "toggleDetails", [_object.action]))();
  }, [(0, _component2.classNames)("topic-rating-tip")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TopicRatingTip);
});
define("discourse/plugins/discourse-ratings/discourse/helpers/rating-list", ["exports", "@ember/template", "discourse/plugins/discourse-ratings/discourse/lib/rating-utilities"], function (_exports, _template, _ratingUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = ratingList;
  function ratingList(ratings) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return (0, _template.htmlSafe)((0, _ratingUtilities.ratingListHtml)(ratings, opts));
  }
});
import { template as template_7ac30f8262bb43e3b05f8c25f3565891 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_7ac30f8262bb43e3b05f8c25f3565891(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;

define("discourse/plugins/discourse-ratings/discourse/components/star-rating", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.stars as |star|}}
    {{rating-star value=star rating=this.rating enabled=this.enabled}}<i></i>
  {{/each}}
  */
  {
    "id": "P5AAIAka",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"stars\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"value\",\"rating\",\"enabled\"],[[30,1],[30,0,[\"rating\"]],[30,0,[\"enabled\"]]]]]],[10,\"i\"],[12],[13],[1,\"\\n\"]],[1]],null]],[\"star\"],false,[\"each\",\"-track-array\",\"rating-star\",\"i\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/components/star-rating.hbs",
    "isStrictMode": false
  });
  const StarRating = dt7948.c(class StarRating extends _component.default {
    stars = [1, 2, 3, 4, 5];
    enabled = false;
  }, [(0, _component2.classNames)("star-rating"), (0, _component2.tagName)("span")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StarRating);
});
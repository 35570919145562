define("discourse/plugins/discourse-ratings/discourse/components/select-rating", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "@ember-decorators/object", "@ember/template-factory"], function (_exports, _component, _object, _component2, _object2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{input type="checkbox" checked=this.rating.include class="include-rating"}}
  
  <span>
    {{#if this.rating.typeName}}
      {{this.rating.typeName}}
    {{else}}
      {{i18n "composer.your_rating"}}
    {{/if}}
  </span>
  
  {{star-rating
    enabled=this.rating.include
    rating=this.rating.value
    click=this.triggerUpdateRating
  }}
  */
  {
    "id": "vjOXKq1X",
    "block": "[[[1,[28,[35,0],null,[[\"type\",\"checked\",\"class\"],[\"checkbox\",[30,0,[\"rating\",\"include\"]],\"include-rating\"]]]],[1,\"\\n\\n\"],[10,1],[12],[1,\"\\n\"],[41,[30,0,[\"rating\",\"typeName\"]],[[[1,\"    \"],[1,[30,0,[\"rating\",\"typeName\"]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,3],[\"composer.your_rating\"],null]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\"],[1,[28,[35,4],null,[[\"enabled\",\"rating\",\"click\"],[[30,0,[\"rating\",\"include\"]],[30,0,[\"rating\",\"value\"]],[30,0,[\"triggerUpdateRating\"]]]]]]],[],false,[\"input\",\"span\",\"if\",\"i18n\",\"star-rating\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/components/select-rating.hbs",
    "isStrictMode": false
  });
  const SelectRating = dt7948.c(class SelectRating extends _component.default {
    removeOnUncheck() {
      if (!this.rating.include) {
        this.set("rating.value", 0);
        this.updateRating(this.rating);
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "removeOnUncheck", [(0, _object2.observes)("rating.include")]))();
    triggerUpdateRating() {
      this.updateRating(this.rating);
    }
    static #_2 = (() => dt7948.n(this.prototype, "triggerUpdateRating", [_object.action]))();
  }, [(0, _component2.classNames)("rating-container"), (0, _component2.tagName)("div")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SelectRating);
});